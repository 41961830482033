import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copyright-info',
  templateUrl: './copyright-info.component.html',
})
export class CopyrightInfoComponent {

  @Input() title = '';
  @Input() loading = false;

  constructor() { }

}
