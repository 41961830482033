<section class="section-white parallax-image pb-3" data-aos="fade-up" data-aos-duration="1000">
  <div class="row w-100 mx-0 p-3">
    <div class="parallax d-flex justify-content-center align-items-center">
      <div class="parallax-shadow"></div>
      <img parallax src="assets/images/background.jpg" alt="parallax" class="img-fluid">
      <div class="col-lg-7 col-md-12 mx-0 py-5 px-5 text-center parallax-container">
        <h4 class="mb-0" data-aos="fade-up" data-aos-duration="1000" style="line-height: 1.4">
          {{ 'Parallax.title' | translate | uppercase }}
        </h4>
      </div>
    </div>
  </div>
</section>
