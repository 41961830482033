import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InstagramImage } from '../../models/instagram.model';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {

  constructor() {}

  getImages(): Observable<InstagramImage[]> {
    return of([
      { src: "assets/images/instagram/1.png",
        alt: "Follow Me 1",
        link: "https://www.instagram.com/p/CGQKn2qnA2A/",
        class: "instagram-image-responsive"
      },
      { src: "assets/images/instagram/2.png",
        alt: "Follow Me 2",
        link: "https://www.instagram.com/p/B3cxiheH9Mw/",
        class: ""
      },
      { src: "assets/images/instagram/3.png",
        alt: "Follow Me 3",
        link: "https://www.instagram.com/p/B3SMizAnS1j/",
        class: ""
      },
      { src: "assets/images/instagram/4.png",
        alt: "Follow Me 4",
        link: "https://www.instagram.com/p/Cau8_FUMRq7/",
        class: ""
      },
      { src: "assets/images/instagram/5.png",
        alt: "Follow Me 5",
        link: "https://www.instagram.com/p/CckSliHMo26/",
        class: "instagram-image-responsive"
      },
      { src: "assets/images/instagram/6.png",
        alt: "Follow Me 6",
        link: "https://www.instagram.com/p/CakXmOrse9A/",
        class: "instagram-image-responsive"
      }
    ]);
  }

}
