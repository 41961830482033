import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SplashAnimationType, LanguageService } from './modules/shared';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'annevanilla-v2';

  SplashAnimationType = SplashAnimationType;

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService
  ){ }

  ngOnInit(): void{
    this.languageService.initLanguage()
    this.titleService.setTitle('Annevanilla | Photography');

    this.metaService.addTags([
      { name: 'keywords', content: 'Annevanilla | Photography' },
      { name: 'description', content: 'Soy Ana, una fotógrafa apasionada por capturar momentos únicos e irrepetibles.' },
    ]);

    AOS.init();
  }
}
