import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cookies-info',
  templateUrl: './cookies-info.component.html',
})
export class CookiesInfoComponent {

  @Input() title = '';
  @Input() loading = false;

  constructor() { }

}
