import { Component, OnInit} from '@angular/core';

@Component({
    selector: 'cookie-message',
    templateUrl: './cookie-message.html'
})

export class CookieMessageComponent implements OnInit {
  acceptedCookie = false;

  constructor() {}

  ngOnInit(){
    this.acceptedCookie = this._checkCookie();
  }

  private _checkCookie(): boolean {
    return localStorage.getItem('cookies') ? true : false;
  }

  accept(){
    localStorage.setItem('cookies', 'true');
    this.acceptedCookie = true;
  }

  deny(){
    window.location.href = 'https://www.google.es'
  }

  openInfo() {
    console.log('OPEN INFO COOKIES');
  }

}
