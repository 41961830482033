<section class="section" id='review'>
  <div class="w-100 row d-flex justify-content-center align-items-center pt-4">
    <div class="col-12 pt-2 col-md-7 d-flex justify-content-center text-center">
      <h5 data-aos="fade-up" data-aos-duration="1000" class="review-title">{{ 'Reviews.title' | translate | uppercase }}</h5>
    </div>
    <div class="col-md-5 review-hidden"></div>
  </div>
  <div class="d-flex justify-content-center mb-3">
    <div class="keen-slider" #sliderRef>
      <div class="keen-slider__slide d-flex justify-content-center" *ngFor="let review of reviews">
        <div class="row section-container pb-3" >
          <div class="col-12 col-md-6 mb-md-4 mb-0 mb-md-0 pl-3">
            <div class="my-5 text-center">
              <h1 data-aos="fade-up" data-aos-duration="1000">{{ review.title | uppercase }}</h1>
              <p class="text-center" data-aos="fade-up" data-aos-duration="1000">{{ review.date }}</p>
            </div>
            <p class="text-center" data-aos="fade-up" data-aos-duration="1000">"{{ review.text }}"</p>
          </div>
          <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
            <img class="p-md-3 p-0" [src]="review.image" />
          </div>
        </div>
      </div>
      <svg (click)="prevSlide()" class="arrow arrow--left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
      </svg>
      <svg *ngIf="slider" class="arrow arrow--right" (click)="nextSlide()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    </div>

  </div>
</section>
