<nav [@animateMenu] class="navbar main-navbar on-top" [class.nav-shadow]='this.pageYPosition > 0'>
    <div class="container">
        <a class="navbar-brand text-light" [routerLink]="'inicio'">
          <img [src]="pageYPosition > 0 ? 'assets/images/logo.png' : 'assets/images/logo-white.png'" [width]="pageYPosition > 0 ? '175' : '250'" />
        </a>
        <ul ngbNav #nav="ngbNav" class="menu-ul">
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderInicio, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                <a ngbNavLink (click)='scroll("banner")'>
                  <span class="underline nav-text" [ngClass]="this.pageYPosition > 0 ? 'nav-text-primary' : 'nav-text-secondary'">
                    {{ "Header.Item1" | translate | uppercase }}
                  </span>
                </a>
                <ng-template ngbNavContent></ng-template>
            </li>
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderPorfolio, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                <a ngbNavLink (click)='scroll("porfolio")'>
                  <span class="underline nav-text" [ngClass]="this.pageYPosition > 0 ? 'nav-text-primary' : 'nav-text-secondary'">
                    {{ "Header.Item2" | translate | uppercase }}
                  </span>
                </a>
                <ng-template ngbNavContent></ng-template>
            </li>
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderSobremi, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                <a ngbNavLink (click)='scroll("about")'>
                  <span class="underline nav-text" [ngClass]="this.pageYPosition > 0 ? 'nav-text-primary' : 'nav-text-secondary'">
                    {{ "Header.Item3" | translate | uppercase }}
                  </span>
                </a>
                <ng-template ngbNavContent></ng-template>
            </li>
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderContacto, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                <a ngbNavLink (click)='scroll("follow-me")'>
                  <span class="underline nav-text" [ngClass]="this.pageYPosition > 0 ? 'nav-text-primary' : 'nav-text-secondary'">
                    {{ "Header.Item4" | translate | uppercase }}
                  </span>
                </a>
                <ng-template ngbNavContent></ng-template>
            </li>
            <li class="d-flex align-items-center language-menu">
                <div ngbDropdown class="d-inline-block">
                    <div class="language-container" id="dropdownForm1" ngbDropdownToggle>
                        <span *ngIf='languageFormControl.value == "en"' class="flag-text" [ngClass]="this.pageYPosition > 0 ? 'text-primary' : 'text-inverse'">{{ 'English' | uppercase }}</span>
                        <span *ngIf='languageFormControl.value == "es"' class="flag-text" [ngClass]="this.pageYPosition > 0 ? 'text-primary' : 'text-inverse'">{{ 'Español' | uppercase }}</span>
                        <span *ngIf='languageFormControl.value == "ca"' class="flag-text" [ngClass]="this.pageYPosition > 0 ? 'text-primary' : 'text-inverse'">{{ 'Catalan' | uppercase }}</span>
                        <svg class='arrow-language' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                        <div (click)='changeLanguage("es")' style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;' class="language-container language-container-selector active" [class.active]='languageFormControl.value == "es"'>
                            <img width="20px" src="https://www.worldometers.info/img/flags/sp-flag.gif" alt="Español">
                            <span class="flag-text text-primary">{{ 'Español' | uppercase }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                              </svg>
                        </div>
                        <div (click)='changeLanguage("ca")' style='margin-top: 1px !important; border-radius: 0px !important;' class="language-container language-container-selector" [class.active]='languageFormControl.value == "ca"'>
                          <img width="20px" src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Catalonia.svg" alt="Catalan">
                          <span class="flag-text text-primary">{{ 'Catalan' | uppercase }}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                        <div (click)='changeLanguage("en")' style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;" class="language-container language-container-selector" [class.active]='languageFormControl.value == "en"'>
                            <img width="20px" src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                            <span class="flag-text text-primary">{{ 'English' | uppercase }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <!-- MENU WRAPPER -->
        <div class="menu-wrapper">
            <div [class.animate]='responsiveMenuVisible' (click)='responsiveMenuVisible = !responsiveMenuVisible' class="hamburger-menu"></div>
        </div>
        <!-- MENU RESPONSIVE -->
        <div class="menu-responsive" [ngStyle]="{'pointer-events': !responsiveMenuVisible ? 'none' : '' }">
            <aside [class.aside-show]='responsiveMenuVisible' [class.nav-shadow]='this.pageYPosition>0' class="on-top">
              <div class="row responsive-logo">
                <img src="assets/images/logo.png" width="250" />
              </div>
                <nav>
                    <ol>
                      <li (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderMobileInicio, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                        <a (click)='scroll("banner")'>
                            {{ "Header.Item1" | translate }}
                        </a>
                      </li>
                      <li (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderMobilePorfolio, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                        <a (click)='scroll("porfolio")'>
                            {{ "Header.Item2" | translate }}
                        </a>
                      </li>
                      <li (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderMobileSobremi, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                        <a (click)='scroll("about")'>
                            {{ "Header.Item3" | translate }}
                        </a>
                      </li>
                      <li (click)='analyticsService.sendAnalyticEvent(AnalyticsAction.HeaderMobileContacto, AnalyticsCategory.Header, AnalyticsEvent.Click)'>
                        <a (click)='scroll("follow-me")'>
                          {{ "Header.Item4" | translate }}
                        </a>
                      </li>
                      <li>
                        <div class="language-container">
                          <div (click)='changeLanguage("es")' style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;' class="language-container language-container-selector active" [class.active]='languageFormControl.value == "es"'>
                              <img src="https://www.worldometers.info/img/flags/sp-flag.gif" alt="Español">
                              <span class="flag-text">Español</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                </svg>
                          </div>
                          <div (click)='changeLanguage("en")' style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;" class="language-container language-container-selector" [class.active]='languageFormControl.value == "en"'>
                              <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                              <span class="flag-text">English</span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                              </svg>
                          </div>
                        </div>
                      </li>
                    </ol>
                </nav>
            </aside>
        </div>
    </div>
</nav>
