import { Component, Input, HostBinding, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AccentColor } from '../../resources/accent-color.enum';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})

export class ModalComponent {
  @Input() title = '';
  @Input() loading = false;
  @Input() accentColor: AccentColor = AccentColor.primary;
  @Input() header: TemplateRef<any>;
  @Input() bodyClass = 'modal-body';
  @Input() footerClass = 'border-top';
  @Input() draggable = true;
  @Input() listenDismissEvent: boolean;

  @HostBinding('style') style: SafeStyle;

  @Output() dismiss = new EventEmitter<void>();

  AccentColor = AccentColor;

  constructor(
    private _sanitizer: DomSanitizer,
    private _activeModal: NgbActiveModal
  ) {
    this.style = this._sanitizer.bypassSecurityTrustStyle('display: flex; flex-direction: column; overflow: hidden;');
  }

  onDismiss() {
    if (this.listenDismissEvent){
      this.dismiss.emit();
    } else {
      this._activeModal.dismiss();
    }
  }

}
