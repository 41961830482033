import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from './components/modal/modal.component';
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from './components/header/header.component'
import { PreFooterComponent } from './components/pre-footer/pre-footer.component';
import { CookieMessageComponent } from './components/cookie-message/cookie-message.component';
import { PrivacyInfoComponent } from './components/privacy-info/privacy-info.component';
import { CopyrightInfoComponent } from './components/copyright-info/copyright-info.component';
import { CookiesInfoComponent } from './components/cookies-info/cookies-info.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { InstagramService } from './services/instagram/instagram.service';

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    CookieMessageComponent,
    HeaderComponent,
    ModalComponent,
    SplashScreenComponent,
    PrivacyInfoComponent,
    CookiesInfoComponent,
    CopyrightInfoComponent,
    PreFooterComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    DragDropModule,
    RouterModule,
    HttpClientModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    InstagramService
  ],
  exports: [
    CookieMessageComponent,
    HeaderComponent,
    SplashScreenComponent,
    ModalComponent,
    PrivacyInfoComponent,
    PreFooterComponent,
    FooterComponent
  ]
})
export class SharedModule { }
