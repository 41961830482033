import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AnalyticsAction } from '../../resources/analytics-action.enum';
import { AnalyticsCategory } from '../../resources/analytics-category.enum';
import { AnalyticsEvent } from '../../resources/analytics-event.enum';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private $gaService: GoogleAnalyticsService
  ) { }

  sendAnalyticEvent(action: AnalyticsAction, category: AnalyticsCategory, event: AnalyticsEvent): void {
    this.$gaService.event(action, category, event)
  }

  sendAnalyticPageView(path: string, title: string){
    this.$gaService.pageView(path, title)
  }

}
