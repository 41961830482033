import { Component } from '@angular/core';
import { trigger, query, stagger, animate, style, transition } from '@angular/animations'
import { AnalyticsService } from '../../../shared';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  animations:[
    trigger("animateInfo", [
      transition(":enter", [
        query("*", [
          style({opacity: 0, transform: "translateY(100%)"}),
          stagger(50, [
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity:1, transform: "none"})
            )
          ])
        ])
      ])
    ])
  ]
})
export class InfoComponent {

  constructor(public analyticsService: AnalyticsService) { }

}
