<section class="section-white pt-3" id='follow-me'>
  <div class="row d-grid text-center justify-content-center" data-aos="fade-up" data-aos-duration="1000">
    <h2 class="mb-0">{{ 'Instagram.FollowMe' | translate | uppercase }}</h2>
    <label>@annevanilla</label>
  </div>
  <div class="py-2">
    <div class="row mx-0 p-3 pt-0 w-100 d-flex justify-content-center" *ngIf="currentWidth > 796; else instagramGrid" data-aos="fade-up" data-aos-duration="1000">
      <div *ngFor="let image of images; let i = index">
        <div class="instagram-container" [ngClass]="[i % 2 == 0 ? 'mb-5' : 'mt-5', image.class]" *ngIf="i !== 5">
          <img data-aos="fade-up" data-aos-duration="1000" [src]="image.src" alt="image.alt" (click)="openLink(image.link)">
        </div>
      </div>
    </div>

    <ng-template #instagramGrid>
      <div class="p-3" data-aos="fade-up" data-aos-duration="1000">
        <div class="follow-img-responsive" *ngFor="let image of images; let i = index">
          <img [src]="image.src" alt="image.alt" (click)="openLink(image.link)">
        </div>
      </div>
    </ng-template>

  </div>
</section>
