import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AnalyticsService } from 'src/app/modules/shared/services/analytics/analytics.service';

@Component({
  selector: 'app-parallax-image',
  templateUrl: './parallax-image.component.html',
  styleUrls: ['./parallax-image.component.scss']
})
export class ParallaxImageComponent implements OnInit {

  constructor(
    private router: Router,
    public analyticsService: AnalyticsService
  ) { }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }

}
