import { Component, ElementRef, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { AnalyticsService } from '../../../shared';
import { Subject, timer } from 'rxjs';
import { takeUntil, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements AfterViewInit, OnDestroy {


  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;

  reviews: Array<any> = [];
  currentSlide: number = 0;
  dotHelper: Array<Number> = [];
  slider: KeenSliderInstance = null;

  private _start$: Subject<boolean> = new Subject();
  private _stop$: Subject<boolean> = new Subject();
  private _transitionTime: number = 10000;

  constructor(public analyticsService: AnalyticsService) {
    this._initReviews();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        loop: true,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
        defaultAnimation: {
          duration: 2500
        }
      })
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    })

    this._initTransitions();
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  private _initReviews() {
    this.reviews = [
      {
        title: 'Antoni & Berta',
        date: '07/09/2019',
        text: 'Una chica supermaja y superflexible. Los resultados de las fotos y el álbum son espectaculares. Inmejorable relación calidad precio.',
        image: 'assets/images/reviews/1.jpg'
      },
      {
        title: 'Barbara & Damien',
        date: '25/05/2019',
        text: 'Una gran experiencia para nuestra Boda. No hay palabras para agradecerle este increíble trabajo de nuestras fotos. Ana fue increíble desde el principio hasta el final de la ejecución en el gran día. Las fotos son tan naturales que nos encantó mucho. Un trabajo artístico y muy natural, no solo las fotos posadas de siempre. ¡Gracias de nuevo Ana! ¡Fuiste fundamental para que nuestro día tan especial este grabado para siempre!',
        image: 'assets/images/reviews/2.jpg'
      },
      {
        title: 'Elia & Jose Luis',
        date: '7/03/2018',
        text: 'Ana es una fotógrafa excepcional en todos los sentidos. Su trabajo nos ha encantado, hace unas fotos preciosas, ha sabido captar la esencia de cada momento, ¡sin lugar a dudas la recomendaría a todo el mundo! Lo que más nos ha gustado es que se ha adaptado perfectamente a nuestras expectativas ya que no han sido las típicas fotos de boda posadas, sino que han sido fotos espontáneas que reflejan emociones y sentimientos.',
        image: 'assets/images/reviews/3.jpg'
      },
      {
        title: 'Mireia & Christian ',
        date: '28/09/2019',
        text: 'Ana fue nuestra fotógrafa y la experiencia fue fantástica. Un trato muy personal, las fotografías preciosas y naturales. Nos sentimos muy cómodos trabajando con ella; el día del enlace hizo un trabajo excelente y pasando totalmente desapercibida. ¡Nosotros la recomendamos 100%!',
        image: 'assets/images/reviews/4.jpg'
      }
    ]
  }

  private _initTransitions() {
    this._start$.pipe(
      filter(x => !!x), // Si el valor es true
      switchMap(() => timer(0, this._transitionTime).pipe(
        takeUntil(this._stop$)
      ))
    ).subscribe(_ => this.slider.next());
    this._start$.next(true);
  }

  nextSlide() {
    this._stop$.next(true);
    this.slider.next();
    this._start$.next(true);
  }

  prevSlide() {
    this._stop$.next(true);
    this.slider.prev();
    this._start$.next(true);
  }

}
