import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService, InstagramService, InstagramImage, AnalyticsAction, AnalyticsCategory, AnalyticsEvent } from '../../../shared';

@Component({
  selector: 'app-follow-me',
  templateUrl: './follow-me.component.html',
  styleUrls: ['./follow-me.component.scss']
})
export class FollowMeComponent implements OnInit, AfterViewInit, OnDestroy {

  AnalyticsAction = AnalyticsAction;
  AnalyticsCategory = AnalyticsCategory;
  AnalyticsEvent = AnalyticsEvent;

  images = [];
  currentWidth: number = 0;

  private _destroy = new Subject();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.currentWidth = window.innerWidth;
  }

  constructor(
    public analyticsService: AnalyticsService,
    private _instagramService: InstagramService,
    private _cdk: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit(): void {
    this._instagramService.getImages().pipe(takeUntil(this._destroy))
      .subscribe((images: InstagramImage[]) => this.images = images);
  }

  ngAfterViewInit(): void {
    this.currentWidth = window.innerWidth;
    this._cdk.detectChanges();
  }

  openLink(url: string): void {
    const action = url as AnalyticsAction;
    this.analyticsService.sendAnalyticEvent(action, AnalyticsCategory.FollowMe, AnalyticsEvent.OpenUrl);
    window.open(url, '_blank');
  }

}
