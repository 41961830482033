<section class="section" id="about">
  <div class="d-flex justify-content-center my-3">

    <div class="row mt-3 mb-3 p-3 section-container d-flex justify-content-center text-justify">
      <div class="col-lg-6 col-md-6 col-sm-12 text-center mt-mb-2 mb-md-0 mb-3 pl-md-3 p-0 d-flex justify-content-end" data-aos="fade-up" data-aos-duration="1000">
          <img class="about-image" src="assets/images/home/sobremi.jpg" width="100%">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0 pl-md-5 pr-md-5 pl-2 ">
        <div class="mb-3 about-welcome" data-aos="fade-up" data-aos-duration="1000">
          <h2>{{ 'AboutMe.Hi' | translate | uppercase }}</h2>
        </div>
        <div class="about-description pb-2">
          <p *ngFor='let text of "AboutMe.Paragraphs" | translate'
            [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
          </p>
        </div>
        <button class="btn btn-outline-custom" (click)='scroll("follow-me")'
          data-aos="fade-up" data-aos-duration="1000">
          {{ 'AboutMe.Contact' | translate | uppercase }}
        </button>
      </div>
  </div>
  </div>
</section>
