export enum AnalyticsAction {
  HeaderInicio = 'Header Inicio',
  HeaderPorfolio = 'Header Portfolio',
  HeaderSobremi = 'Header Sobre mí',
  HeaderContacto = 'Header Contacto',
  Privacy = 'Privacy',
  Cookies = 'Cookies',
  Copyright = 'Copyright',

  HeaderMobileInicio = 'Header Mobile Inicio',
  HeaderMobilePorfolio = 'Header Mobile Portfolio',
  HeaderMobileSobremi = 'Header Mobile Sobre mí',
  HeaderMobileContacto = 'Header Mobile Contacto',

  HeaderLanguageSpanish = 'Idioma Español',
  HeaderLanguageEnglish = 'Idioma Inglés',
  HeaderLanguageCatalan = 'Idioma Catalán'

}
