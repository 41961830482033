<section [@animatePreFooter] class="section py-0 py-md-3 d-flex justify-content-center" id="prefooter">

  <div class="row py-4 d-flex justify-content-center align-items-center" style="width: 80%; height: fit-content">
    <div class="col-12 col-lg-4 col-md-6 p-md-5 p-2 text-center h-100 section-compact">
      <h4 class="py-2">{{ 'Info.PreFooter.Follow' | translate | uppercase }}</h4>
      <div class="text-center py-2">
        <a [href]="annevanillaFb" target="_blank"><i class="fab fa-facebook fa-lg pr-2"></i></a> -
        <a [href]="annevanillaInsta" target="_blank"><i class="fab fa-instagram fa-lg px-2"></i></a> -
        <a [href]="annevanillaPhone" target="_blank"><i class="fab fa-whatsapp fa-lg pl-2"></i></a>
      </div>
      <a [href]="annevanillaMail" target="_blank">
        <label style="cursor: pointer;" class="py-2">annevanillafoto@gmail.com</label>
      </a>
    </div>
    <div class="col-12 col-lg-4 col-md-6 p-md-5 p-2 text-center h-100 section-compact separator-left separator-right pre-footer-responsive">
      <img src="assets/images/logo-sm.png" alt="annevanilla-logo-sm" height="80">
    </div>
    <div class="col-12 col-lg-4 col-md-6 p-md-5 p-2 text-center h-100 section-compact">
      <h4 class="py-2">{{ 'Info.PreFooter.More' | translate | uppercase }}</h4>
      <div class="d-flex justify-content-center align-items-center py-2">
        <a class="px-1" (click)="openLegalInfo(LegalType.PRIVACY)">{{ 'Info.PreFooter.Privacy' | translate }}</a>
        <hr class="icon-separator">
        <a class="px-1" (click)="openLegalInfo(LegalType.COPYRIGHT)">{{ 'Info.PreFooter.Copyright' | translate }}</a>
        <hr class="icon-separator">
        <a class="px-1" (click)="openLegalInfo(LegalType.COOKIES)">{{ 'Info.PreFooter.Cookies' | translate }}</a>
      </div>
    </div>
  </div>
</section>
