import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Component, OnInit, HostListener } from '@angular/core';
import { trigger, style, query, transition, stagger, animate } from '@angular/animations'
import { AnalyticsService } from 'src/app/modules/shared/services/analytics/analytics.service';
import { AnalyticsAction } from '../../resources/analytics-action.enum';
import { AnalyticsCategory } from '../../resources/analytics-category.enum';
import { AnalyticsEvent } from '../../resources/analytics-event.enum';
import { LanguageService } from '../../services/language/language.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations:[
    trigger("animateMenu",[
      transition(":enter",[
        query("*", [
          style({opacity: 0, transform: "translateY(-50%)"}),
          stagger(50,[
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity: 1, transform: "none"}))
          ])
        ])
      ])
    ])
  ]
})

export class HeaderComponent implements OnInit {

  pageYPosition: number = 0;
  responsiveMenuVisible: Boolean = false;
  languageFormControl: FormControl<string> = new FormControl();

  AnalyticsAction = AnalyticsAction;
  AnalyticsCategory = AnalyticsCategory;
  AnalyticsEvent = AnalyticsEvent;

  constructor(
    private router: Router,
    public analyticsService: AnalyticsService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.languageFormControl.valueChanges.subscribe(val => this.languageService.changeLanguage(val))
    this.languageFormControl.setValue(this.languageService.language)
  }

  scroll(el) {
    if(document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({ behavior: 'smooth' });
    } else{
      this.router.navigate(['/home']).then(()=> document.getElementById(el).scrollIntoView({behavior: 'smooth'}) );
    }
    this.responsiveMenuVisible=false;
  }

  @HostListener('window:scroll', ['getScrollPosition($event)'])
    getScrollPosition(event: Event) {
      if (event) {
        this.pageYPosition = window.pageYOffset
      }
    }

    changeLanguage(language: string) {
      this.languageFormControl.setValue(language);
    }
}
