<app-modal [title]="title"
  [loading]="loading"
  [bodyClass]="'modal-body overflow-hidden p-0'">
  <ng-container class="body">
    <section id="privacy-section" class="p-4">
      <div class="d-flex flex-column">
        <h5><strong>(1) Propiedad Intelectual e Industrial</strong></h5>
        <p><i>Ana Martínez</i>  es la propietaria de todos los derechos de propiedad intelectual e industrial de:</p>
        <p>(a) este sitio web publicado bajo el dominio <i>annevanilla.com.</i></p>
        <p>(b) todo el material publicado en <i>annevanilla.com</i> (incluyendo, sin limitación, textos, imágenes, fotografías, dibujos, música, marcas o logotipos y estructura)</p>

        <h5><strong>(2) Licencia de uso</strong></h5>
        <p><i>Ana Martínez</i> le concede una licencia universal, no exclusiva, de libre uso y revocable en cualquier momento para:</p>
        <p>(a) visualizar <i>annevanilla.com</i> y todo el material publicado en el mediante el uso de un ordenador o dispositivo móvil a través de un navegador web.</p>
        <p>(b) copiar y almacenar una copia de annevanilla.com o/y de todo el material publicado en el, en la memoria caché de su navegador web.</p>
        <p>(c) imprimir las páginas a través de un dispositivo físico (impresora-papel) o dispositivo virtual (impresora-pdf) siempre y cuando sea, única y exclusivamente, para su uso personal y privado [y no tenga como fin un uso comercial]</p>
        <p>No le autorizo a cualquier otro derecho sobre este sitio o sobre el material publicado en el. Esto significa que todos los derechos quedan reservados.</p>
		    <p>En caso de duda Ud, como visitante de nuestro sitio entiende y acepta que: no puede adaptar, editar, cambiar, transformar, publicar, re-publicar, distribuir, re-distribuir, realizar cualquier tipo de emisión o re-emisión por medio ondas o cualquier otra tecnología de transmisión, mostrar en lugares públicos o privados mediante el uso de elementos de visión o escucha comunitarios este sitio web o el material publicado en el (con independencia de tipo de archivo o elemento) sin mi consentimiento previo por escrito.</p>

        <h5><strong>(3) Minería de datos</strong></h5>
        <p>Queda prohibido el acceso a este sitio y a los contenidos publicados en el mediante el uso de cualquier tipo de sistema de recolección y copia automática de datos o archivos.</p>

        <h5><strong>(4) Permisos específicos</strong></h5>
        <p>Si Ud. desea utilizar los materiales publicados en este sitio para un uso distinto de los autorizados en el apartado Licencia de uso por favor contacte por escrito conmigo enviando un email a <a href="mailto://annevanillafoto@gmail.com"><i>annevanillafoto@gmail.com</i></a>.</p>

        <h5><strong>(5) Protección de nuestros derechos</strong></h5>
        <p>El uso, modificación o distribución no autorizados del material publicado en annevanilla.com puede conllevar el inicio de un procedimiento judicial con las reclamaciones económicas por el daño causado. </p>
      </div>
    </section>
  </ng-container>
</app-modal>
