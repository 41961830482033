<main>
    <app-banner></app-banner>
    <app-introduction></app-introduction>
    <app-photos></app-photos>
    <app-about></app-about>
    <app-info></app-info>
    <app-parallax-image></app-parallax-image>
    <app-review></app-review>
    <app-follow-me></app-follow-me>
    <app-pre-footer></app-pre-footer>
</main>

