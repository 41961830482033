import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxSimpleParallaxJsModule } from 'ngx-simple-parallax-js';
import { HomeComponent } from './pages/home.component';
import { AboutComponent } from './components/about/about.component';
import { BannerComponent } from './components/banner/banner.component';
import { FollowMeComponent } from './components/follow-me/follow-me.component';
import { PhotosComponent } from './components/photos/photos.component';
import { ReviewComponent } from './components/review/review.component';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { ParallaxImageComponent } from './components/parallax-image/parallax-image.component';
import { InfoComponent } from './components/info/info.component';

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    HomeComponent,
    BannerComponent,
    IntroductionComponent,
    AboutComponent,
    PhotosComponent,
    InfoComponent,
    ParallaxImageComponent,
    ReviewComponent,
    FollowMeComponent,
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    CarouselModule,
    SharedModule,
    NgxSimpleParallaxJsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ]
})
export class HomeModule { }
