<div class="position-fixed fixed-bottom m-5" *ngIf="!acceptedCookie">
	<div class="row">
		<div class="card col-lg-4 col-md-6 col-12 p-3">
      <div class="row">
        <div class="col-3 d-flex justify-content-center align-items-center pr-3">
          <img src="../../../../assets/images/COOKIECHAN.png" width="80" class="cookie-image">
        </div>
        <div class="col-9 justify-content-end text-justify">
          <div class="row">
            <label class="inline-cookie cookie-message">Utilizamos cookies propias y de terceros para mejorar tu experiencia de usuario.
              ¿Estás de acuerdo? </label>
          </div>
          <div class="row pt-3">
            <div class="col-12 d-flex justify-content-end">
              <i class="far fa-question-circle fa-2x text-info mr-2 cursor-pointer" (click)="openInfo()"> </i>
              <i class="far fa-check-circle fa-2x text-success mr-2 cursor-pointer"  (click)="accept()"> </i>
              <i class="far fa-times-circle fa-2x text-danger cursor-pointer" (click)="deny()"></i>
            </div>
          </div>
        </div>
      </div>
		</div>
	</div>
</div>
