export enum AnalyticsCategory {
  Header = 'Header',
  LegalInfo = 'LegalInfo',
  SocialInfo = 'SocialInfo',
  Footer = 'Footer',
  About = 'About',
  Contact = 'Contact',
  Home = 'Home',
  Portfolio = 'Portfolio',
  FollowMe = 'Sigueme en instagram'
}
