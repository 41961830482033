import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-privacy-info',
  templateUrl: './privacy-info.component.html',
})
export class PrivacyInfoComponent {

  @Input() title = '';
  @Input() loading = false;

  constructor() { }

}
