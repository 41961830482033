<section class="section-white p-3" id="info">
  <div class="row d-flex justify-content-center text-center" data-aos="fade-up" data-aos-duration="1000">
    <label>{{ 'Info.PreTitle' | translate | uppercase }}</label>
  </div>
  <div class="row  d-flex justify-content-center text-center" data-aos="fade-up" data-aos-duration="1000">
    <h2>{{ 'Info.Title' | translate | uppercase }}</h2>
  </div>
  <div class="row mx-0 d-flex justify-content-center align-items-center">
    <div class="col-12 col-md-4 p-4 text-center h-100 icon-block" data-aos="fade-up" data-aos-duration="2000">
      <img class="py-2" src="assets/images/home/icono1.gif" height="150">
      <h5 class="py-2">{{ 'Info.Session' | translate | uppercase }}</h5>
      <p>{{ 'Info.Session.Text' | translate }}</p>
    </div>
    <div class="col-12 col-md-4 p-4 text-center h-100 icon-block" data-aos="fade-up" data-aos-duration="2000">
      <img class="py-2" src="assets/images/home/icono2.gif" height="150">
      <h5 class="py-2">{{ 'Info.Retouching' | translate | uppercase }}</h5>
      <p>{{ 'Info.Retouching.Text' | translate }}</p>
    </div>
    <div class="col-12 col-md-4 p-4 text-center h-100 icon-block" data-aos="fade-up" data-aos-duration="2000">
      <img class="py-2" src="assets/images/home/icono3.gif" height="150">
      <h5 class="py-2">{{ 'Info.Delivery' | translate | uppercase }}</h5>
      <p>{{ 'Info.Delivery.Text' | translate }}</p>
    </div>
  </div>
</section>
