<app-modal [title]="title"
  [loading]="loading"
  [bodyClass]="'modal-body overflow-hidden p-0'">
  <ng-container class="body">
    <section id="privacy-section" class="p-4">
      <div class="d-flex flex-column">
        <p><i>annevanilla.com</i>, pretende mediante esta declaración formal informar a los visitantes de cual es nuestra política de protección de la privacidad y confidencialidad de los datos de carácter personal que los usuarios facilitan de forma libre y voluntaria.</p>

        <p>En el ejercicio de servir a sus clientes, <i>annevanilla.com</i> adquiere, guarda y transmite información y comunicaciones que los clientes pueden considerar como privada o confidencial. Alguna de esta información, tal como el nombre del cliente, número de teléfono etc., es dada a annevanilla.com por sus clientes para poder pedir sus servicios.</p>

        <h5><strong>1. Seguridad de los datos.</strong></h5>
        <p><i>annevanilla.com</i> protegerá la confidencialidad de la información de los clientes, información contable y comunicaciones personales hasta el grado máximo de protección de acuerdo con la legislación vigente y los intereses de annevanilla.com, sus distribuidores, sus empleados y otros clientes de los servicios de annevanilla.com. Para proteger la perdida, mal uso o alteración de la información que es recogida por los clientes, annevanilla.com tiene medios y procedimientos físicos, electrónicos y de gestión para hacerlo.</p>

        <h5><strong>2. Divulgación de la información del cliente.</strong></h5>
        <p><i>annevanilla.com </i>no podrá divulgar información personal del cliente o de su cuenta a menos que annevanilla.com crea necesario el divulgar esta información para identificar, ponerse en contacto o tomar acciones legales en contra de cualquiera que estuviera interfiriendo en los derechos de annevanilla.com, clientes de annevanilla.com u otros, o a menos que la ley o alguna autoridad legal requiriera esta divulgación de la información. annevanilla.com no podrá divulgar, excepto por las razones citadas anteriormente, a terceras partes los contenidos de correo electrónico o cualquier otra comunicación electrónica que annevanilla.com pudiera guardar o transmitir a sus clientes. Las circunstancias por las cuales annevanilla.com podría divulgar dicha información son cuando:</p>

        <h5><strong>3. Tipo de datos que se recogen.</strong></h5>
        <p>En conformidad con lo expuesto por la Ley Orgánica de Protección de Datos (Ley Orgánica 15/99) de carácter personal, sus datos personales se incluirán en nuestro fichero automatizado. La recogida y tratamiento automatizado de los datos de carácter personal tiene como finalidad principal:</p>
        <p>Recoger los datos básicos de facturación en caso de que se contrate algún servicio, o los datos necesarios en caso de promociones gratuitas. En la web se ofrecen diversos servicios online en los que se demandan los datos necesarios para la prestación del servicio requerido. Este tipo de información es recibida por <i>annevanilla.com</i> y nunca es vendida, cedida o alquilada a otras empresas.</p>
        <p>Obtener la información básica necesaria para responder las consultas que se nos formulen. En los formularios de consulta que se encuentran en la web se recoge aquella información que nos ayuda a responder las consultas o preguntas que nos formulan. Esta información es recibida por annevanilla.com y nunca es vendida, cedida o alquilada a otras empresas.</p>
        <p>Conocer las visitas que reciben nuestra web. <i>Annevanilla.com </i> dispone en su servidor de un programa estadístico que controla a nivel agregado los datos generales de los visitantes a esta web, tales como páginas más vistas, países de origen de los visitantes y similares. La finalidad de estas estadísticas es ofrecer a <i>annevanilla.com</i> una visión general de la web y su evolución, siempre a nivel agregado; por lo que no se controlan visitas de usuarios en concreto.</p>
        <p>Annevanilla.com ha adoptado las medidas de índole técnica y organizativas necesarias, según el nivel de seguridad de protección de datos que le corresponde legalmente, para garantizar la seguridad del tratamiento de datos de carácter personal que constituyen su fichero automatizado. Sin embargo, el usuario debe ser consciente de que en la actualidad las medidas de seguridad en Internet no son inexpugnables y por lo tanto no se está a salvo de posibles intromisiones ilegales e indebidas, que no serían responsabilidad de annevanilla.com ya que ella ha actuado con la diligencia debida para proteger los datos conforme determina la ley.</p>
        <p>Annevanilla.com hará todo lo posible para que sus datos estén siempre actualizados. No obstante, la responsabilidad acerca de la calidad de los datos depende del titular de ellos, de ahí que si existe alguna modificación de los datos el usuario de la web los comunicará al Responsable del Fichero, declinando esta toda responsabilidad en caso de que omita su obligación de notificar los cambios.</p>

        <h5><strong>4. Cesión de datos a otras empresas.</strong></h5>
        <p><i>annevanilla.com </i> no vende, alquila o cede los datos personales de los usuarios de esta web.</p>

        <p><strong>El objetivo de nuestra política de privacidad es respetar al máximo la legislación vigente de protección de datos personales. Si usted tiene cualquier duda sobre la confidencialidad o el tratamiento que reciben sus datos, así como si desea ejercer alguno de lo derechos de información, oposición, rectificación y cancelación que legalmente le corresponden, (o cualquier otro derecho que usted crea que le puede asistir) puede dirigirse a </strong><a href="mailto://annevanillafoto@gmail.com"><i>annevanillafoto@gmail.com.</i></a></p>
      </div>
    </section>
  </ng-container>
</app-modal>
