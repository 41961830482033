import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { Subject, timer } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../../shared';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.currentWidth = window.innerWidth;
  }

  images: any[] = [];
  dotHelper: Array<Number> = [];
  slider: KeenSliderInstance = null;
  currentSlide: number = 0;
  currentWidth: number = 0;

  private _baseUrl: string;
  private _start$: Subject<boolean> = new Subject();
  private _stop$: Subject<boolean> = new Subject();
  private _transitionTime: number = 10000;

  constructor(
    public analyticsService: AnalyticsService,
    private _cdk: ChangeDetectorRef
  ) {
    this._baseUrl = './../../../../../assets/images/home/';
  }

  ngOnInit(): void {
    this.images = [{
      src: `${this._baseUrl}foto2.jpg`,
      alt: 'Weddings',
      title: 'Photos.Weddings'
    },
    {
      src: `${this._baseUrl}foto6.jpg`,
      alt: 'Portraits',
      title: 'Photos.Portraits'
    },
    {
      src: `${this._baseUrl}foto4.jpg`,
      alt: 'Family',
      title: 'Photos.Family',
    },
    {
      src: `${this._baseUrl}foto3.jpg`,
      alt: 'Pregnancy',
      title: 'Photos.Pregnancy'
    }];
  }

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        loop: true,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel
        },
        defaultAnimation: {
          duration: 1500
        }
      })
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ]
    })
    this.currentWidth = window.innerWidth;
    this._cdk.detectChanges();
    this._initTransitions();
  }

  private _initTransitions() {
    this._start$.pipe(
      filter(x => !!x), // Si el valor es true
      switchMap(() => timer(0, this._transitionTime).pipe(
        takeUntil(this._stop$)
      ))
    ).subscribe(_ => this.slider.next());
    this._start$.next(true);
  }

}
