import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/modules/shared/services/analytics/analytics.service';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {

  constructor(public analyticsService: AnalyticsService) { }

  ngOnInit(): void { }

}
