import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { trigger, query, stagger, animate, style, transition } from '@angular/animations'
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { CopyrightInfoComponent } from './../copyright-info/copyright-info.component';
import { CookiesInfoComponent } from './../cookies-info/cookies-info.component';
import { PrivacyInfoComponent } from './../privacy-info/privacy-info.component';
import { AnalyticsAction } from '../../resources/analytics-action.enum';
import { AnalyticsCategory } from '../../resources/analytics-category.enum';
import { AnalyticsEvent } from '../../resources/analytics-event.enum';

export enum LegalType {
  PRIVACY,
  COOKIES,
  COPYRIGHT
}

@Component({
  selector: 'app-pre-footer',
  templateUrl: './pre-footer.component.html',
  styleUrls: ['./pre-footer.component.scss'],
  animations:[
    trigger("animatePreFooter", [
      transition(":enter", [
        query("*", [
          style({ opacity: 0, transform: "translateY(100%)" }),
          stagger(50, [
            animate(
              "1000ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            )
          ])
        ])
      ])
    ])
  ]
})
export class PreFooterComponent {

  AnalyticsAction = AnalyticsAction;
  AnalyticsCategory = AnalyticsCategory;
  AnalyticsEvent = AnalyticsEvent;
  LegalType = LegalType;

  currentYear: number = new Date().getFullYear();
  annevanillaFb: string = 'https://www.facebook.com/annevanilla';
  annevanillaPhone: string = 'https://wa.link/1gizlj';
  annevanillaInsta: string = 'https://www.instagram.com/annevanilla/';
  annevanillaMail: string = 'mailto:annevanillafoto@gmail.com';

  constructor(
    private _translate: TranslateService,
    private _modalService: NgbModal,
    private _analyticsService: AnalyticsService
  ) { }

  openLegalInfo(type: LegalType) {
    let modal: NgbModalRef = null;
    switch (type) {
      case LegalType.PRIVACY:
        this._analyticsService.sendAnalyticEvent(AnalyticsAction.Privacy, AnalyticsCategory.LegalInfo, AnalyticsEvent.OpenModal);
        modal = this._modalService.open(PrivacyInfoComponent, {
          size: 'lg',
          centered: true,
        });
        modal.componentInstance.title = this._translate.instant('Legal.Privacy.title');
        break;
      case LegalType.COOKIES:
        this._analyticsService.sendAnalyticEvent(AnalyticsAction.Cookies, AnalyticsCategory.LegalInfo, AnalyticsEvent.OpenModal);
        modal = this._modalService.open(CookiesInfoComponent, {
          size: 'lg',
          centered: true,
        });
        modal.componentInstance.title = this._translate.instant('Legal.Cookies.title');
        break;
      case LegalType.COPYRIGHT:
        this._analyticsService.sendAnalyticEvent(AnalyticsAction.Copyright, AnalyticsCategory.LegalInfo, AnalyticsEvent.OpenModal);
        modal = this._modalService.open(CopyrightInfoComponent, {
          size: 'lg',
          centered: true,
        });
        modal.componentInstance.title = this._translate.instant('Legal.Copyright.title');;
        break;
    }
    modal.result.then(_ => {}, _ => {});
  }

}
