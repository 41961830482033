<div class="base-dialog" [style.display]="'contents'" [cdkDragDisabled]="!draggable"
  cdkDrag cdkDragRootElement=".modal-content" cdkDragBoundary=".modal">
  <div cdkDragHandle class="modal-header d-block p-0">
    <div *ngIf="!header; else headerTemplate">
      <div class="row no-gutters py-3 px-4">
        <h3 class="modal-title mb-0 fw-6"
          [class.text-primary]="accentColor === AccentColor.primary"
          [class.text-danger]="accentColor === AccentColor.danger">
          {{ title | translate }}
        </h3>
        <button type="button" class="close" aria-label="Close" (click)="onDismiss()">
          <i class="fas fa-times fs-20"></i>
        </button>
      </div>
    </div>
    <ng-template #headerTemplate>
      <ng-container *ngTemplateOutlet="header"></ng-container>
    </ng-template>
  </div>
  <div [class]="bodyClass">
    <ng-content select=".body"></ng-content>
  </div>
  <!-- <div class="modal-footer" [ngClass]="footerClass">
    <ng-content select=".footer"></ng-content>
  </div> -->
</div>
