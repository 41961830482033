<section class="section-white" id='porfolio'>
  <!-- FULL SECTION -->
  <div class="row mx-0 px-3 pb-3 pt-0 w-100" [hidden]="currentWidth < 796" data-aos="fade-up" data-aos-duration="1000">
    <div class="col-lg-3 col-md-6 col-sm-12 pr-2 pb-lg-0 pb-3" [style.height]="'600px'" *ngFor="let image of images" >
      <div class="photo clean">
        <img [src]="image.src" [alt]="image.alt">
        <div class="overlay">
          <span>{{ image.title | translate | uppercase }}</span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
  <!-- END FULL SECTION -->
  <!-- MOBILE SECTION -->
  <div class="row mx-0 px-3 pb-3 pt-0 w-100" [hidden]="currentWidth > 796" data-aos="fade-up" data-aos-duration="1000">
    <div class="keen-slider" #sliderRef>
      <div class="keen-slider__slide d-flex justify-content-center" *ngFor="let image of images">
        <div class="col-12 px-0">
          <div class="photo clean">
            <img [src]="image.src" [alt]="image.alt">
            <div class="overlay">
              <span>{{ image.title | translate | uppercase }}</span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END MOBILE SECTION -->
</section>
